import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import type {
  DownPricingCountryConfigs,
  DownPricingPhaseConfigs,
} from '@gql_codegen/price-management-config-types';
import { notification } from 'antd';

type DownPricingTrackConfig = {
  track: string;
  phaseConfigs: DownPricingPhaseConfigs;
  isTrackEdited?: boolean;
};

export type ClusterType = {
  priceCluster: string;
  trackConfigs: DownPricingTrackConfig[];
  isClusterEdited?: boolean;
};
interface DownPricingStoreType {
  downpricingConfigsList: {
    country: string;
    priceClusterConfigs: ClusterType[];
    isCountryEdited?: boolean;
  }[];
  setQueryData: (data: DownPricingCountryConfigs[]) => void;
  resetIsEditedCountry: (country: string) => void;
  getCountryData: (country: string) => DownPricingCountryConfigs | undefined;
  setTrackConfigData: ({
    country,
    cluster,
    track,
    phaseConfigs,
  }: {
    country: string;
    cluster: string;
    track: string;
    phaseConfigs: DownPricingPhaseConfigs;
  }) => void;
  cloneTrack: ({
    country,
    cluster,
    track,
    newTrackName,
  }: {
    country: string;
    cluster: string;
    track: string;
    newTrackName: string;
  }) => void;
}

export const useDownPricingStore = create<DownPricingStoreType>()(
  devtools(
    immer((set, get) => ({
      downpricingConfigsList: [],
      setQueryData: (data) =>
        set((state) => {
          state.downpricingConfigsList = data;
        }),
      getCountryData: (country) =>
        get().downpricingConfigsList.find((c) => c.country === country),
      resetIsEditedCountry: (country) =>
        set((state) => {
          const foundCountry = state.downpricingConfigsList.find(
            (ctr) => ctr.country === country,
          );
          if (!foundCountry) {
            return notification.error({
              message: 'Country not found',
            });
          }
          foundCountry.isCountryEdited = false;
          foundCountry.priceClusterConfigs.forEach((cl) => {
            cl.isClusterEdited = false;
            cl.trackConfigs.forEach((tr) => {
              tr.isTrackEdited = false;
            });
          });
        }),
      setTrackConfigData: ({ country, cluster, track, phaseConfigs }) =>
        set((state) => {
          const foundCountry = state.downpricingConfigsList.find(
            (c) => c.country === country,
          );
          if (!foundCountry)
            return notification.error({
              message: 'Country not found',
            });
          const foundCluster = foundCountry.priceClusterConfigs.find(
            (cl) => cl.priceCluster === cluster,
          );
          if (!foundCluster)
            return notification.error({
              message: 'Price cluster not found',
            });
          const foundTrack = foundCluster.trackConfigs.find(
            (t) => t.track === track,
          );
          if (!foundTrack)
            return notification.error({
              message: 'Track not found',
            });
          foundTrack.phaseConfigs = phaseConfigs;
          foundCountry.isCountryEdited = true;
          foundCluster.isClusterEdited = true;
          foundTrack.isTrackEdited = true;
        }),
      cloneTrack: ({ country, cluster, track, newTrackName }) =>
        set((state) => {
          const foundCountry = state.downpricingConfigsList.find(
            (c) => c.country === country,
          );
          if (!foundCountry)
            return notification.error({
              message: 'Country not found',
            });
          const foundCluster = foundCountry.priceClusterConfigs.find(
            (cl) => cl.priceCluster === cluster,
          );
          if (!foundCluster)
            return notification.error({
              message: 'Price cluster not found',
            });
          const foundTrack = foundCluster.trackConfigs.find(
            (t) => t.track === track,
          );
          if (!foundTrack)
            return notification.error({
              message: 'Track not found',
            });
          foundCluster.trackConfigs = [
            ...foundCluster.trackConfigs,
            {
              track: newTrackName,
              phaseConfigs: foundTrack.phaseConfigs,
              isTrackEdited: true,
            },
          ].sort((a, b) => {
            if (a.track > b.track) {
              return 1;
            }
            if (a.track < b.track) {
              return -1;
            }
            return 0;
          });
          foundCountry.isCountryEdited = true;
          foundCluster.isClusterEdited = true;
        }),
    })),
    {
      name: 'DownpricingStore',
      anonymousActionType: 'DownpricingStoreAction',
    },
  ),
);
