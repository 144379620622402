import type { CollapseProps } from 'antd';
import { Collapse, Button, Spin, App } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
// import { formatServerError } from '@src/shared/formating/format-server-error';
// import { PhasesKeys } from './entities/ClusterCollapse/phasesTableConfig';
import {
  useGetOffloadingConfigQuery,
  useUpdateOffloadingConfigMutation,
  OffloadingConfigInputSchema,
} from '@gql_codegen/price-management-config-types';
import { useConvertGeneratedQueryToSuspense } from '@src/shared/hooks/use-convert-generated-query-to-suspence';
import { TrackCollapse } from './entities/TrackCollapse';
import { useOffloadingStore } from './store/offloadingConfigStore';
import { formatServerError } from '@src/shared/formating/format-server-error';

export const OffloadingConfig: React.FC = () => {
  const [queryData, setQueryData, getCountryData, resetIsEditedCountry] =
    useOffloadingStore((state) => [
      state.offloadingConfigsList,
      state.setQueryData,
      state.getCountryData,
      state.resetIsEditedState,
    ]);
  const [editedTracksCount, setEditedTracksCount] = useState(0);

  const offloadingQuery = useConvertGeneratedQueryToSuspense(
    useGetOffloadingConfigQuery,
    undefined,
  );

  const { mutate, isPending } = useUpdateOffloadingConfigMutation();

  useEffect(() => {
    setQueryData(offloadingQuery.data.getOffloadingConfig);
  }, [offloadingQuery.data.getOffloadingConfig, setQueryData]);
  const { notification } = App.useApp();

  const handleCountrySave = useCallback(
    (country: string) => {
      const countryData = getCountryData(country);
      if (!countryData)
        return notification.error({ message: 'Country data not found' });
      const inputParsedResult =
        OffloadingConfigInputSchema().safeParse(countryData);

      if (!inputParsedResult.success)
        return notification.error({
          message: 'Something wrong with data, please inform devs about it',
          description: 'Data format does not fit for the mutation',
        });

      mutate(
        { input: inputParsedResult.data },
        {
          onSuccess: () => {
            resetIsEditedCountry(country);
            notification.success({
              message: 'Configuration saved successfully!',
            });
          },
          onError: (error) => {
            //handle error
            notification.error({ message: formatServerError(error) });
          },
        },
      );
    },
    [getCountryData, mutate, resetIsEditedCountry, notification],
  );

  const countryItems: CollapseProps['items'] = useMemo(
    () =>
      queryData.map((country, idx) => {
        const clusterItems = country.experimentConfigs.map((track, i) => {
          return {
            key: i,
            label: track.experimentTrack,
            style: { background: track.isTrackEdited && '#ffff00' },
            ['data-qa-selector']: `trackCollapseItem-${i}`,
            children: (
              <TrackCollapse
                country={country.country}
                trackConfig={track}
                index={i}
                setEditedTracksCount={setEditedTracksCount}
              />
            ),
          };
        });
        return {
          key: idx,
          label: country.country,
          ['data-qa-selector']: `countryCollapseItem-${idx}`,
          children: <Collapse size="small" items={clusterItems} />,
          extra: country.isCountryEdited && (
            <>
              {isPending && <Spin />}{' '}
              <Button
                type="primary"
                onClick={(event) => {
                  // If you don't want click extra trigger collapse, you can prevent this:
                  event.stopPropagation();
                  handleCountrySave(country.country);
                }}
                disabled={editedTracksCount > 0}
                data-qa-selector="saveChangesButton"
              >
                Save changes
              </Button>
            </>
          ),
        };
      }),
    [queryData, handleCountrySave, isPending, editedTracksCount],
  );

  return (
    <>
      {/* <HStack justifyContent="space-between" className={css({ m: '15px 0' })}>
        <Popconfirm
          title="Warning"
          description={
            <>
              <div>
                Saving a new experiment track configuration will overwrite the
                existing data.
              </div>
              <div>
                This action cannot be undone. Are you sure you want to proceed?
              </div>
            </>
          }
          // onConfirm={() => {}}
          okText={
            <span data-qa-selector="createNewDownPricingConfigButtonProceed">
              Proceed
            </span>
          }
          cancelText={
            <span data-qa-selector="createNewDownPricingConfignButtonCancel">
              Cancel
            </span>
          }
        >
          <Button
            type="default"
            data-qa-selector="createNewDownPricingConfigButton"
          >
            <PlusOutlined /> Create new Donwpricing Config
          </Button>
        </Popconfirm>
      </HStack> */}

      <Collapse
        data-qa-selector="countryCollapseWrapper"
        items={countryItems}
      />
    </>
  );
};
