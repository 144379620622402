import { Table, Form, Button, InputNumber, Popover, Select } from 'antd';
import { EditOutlined, CheckOutlined, CopyOutlined } from '@ant-design/icons';
import type { TableColumnType } from 'antd';
import type { OffloadingPriceClusterConfig } from '@gql_codegen/price-management-config-types';
import { useState, useMemo } from 'react';
import { FormProvider, useForm, FieldPath } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import { FormItem } from 'react-hook-form-antd';
import { useOffloadingStore } from '../store/offloadingConfigStore';
import { useConvertGeneratedQueryToSuspense } from '@src/shared/hooks/use-convert-generated-query-to-suspence';
import { useGetDictionariesQuery } from '@gql_codegen/price-management-config-types';
import type { FormProps } from 'antd';

import {
  OffloadingConfigSchemaType,
  OffloadingConfigSchema,
} from '../model/offloadingConfigSchema';
import type { OffloadingTrackConfig } from '../store/offloadingConfigStore';
import { css } from '@styled-system/css';
import { HStack } from '@styled-system/jsx';
type TrackCollapseProps = {
  country: string;
  trackConfig: OffloadingTrackConfig;
  index: number;
  setEditedTracksCount: React.Dispatch<React.SetStateAction<number>>;
};

type FieldType = {
  experimentTrack: string;
};

export const TrackCollapse: React.FC<TrackCollapseProps> = ({
  country,
  trackConfig,
  index,
  setEditedTracksCount,
}) => {
  const methods = useForm<OffloadingConfigSchemaType>({
    resolver: zodResolver(OffloadingConfigSchema),
    // defaultValues: phaseConfigs,
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  });

  const [offloadingConfigsList, setTrackConfigData, cloneTrack] =
    useOffloadingStore((state) => [
      state.offloadingConfigsList,
      state.setTrackConfigData,
      state.cloneTrack,
    ]);

  const [isClonePopoverOpen, setIsClonePopoverOpen] = useState(false);

  const [isEditMode, setEditMode] = useState(false);
  const isMutating = useIsMutating() > 0;
  const isFetching = useIsFetching() > 0;

  // const offloadingQuery = useConvertGeneratedQueryToSuspense(
  //   useGetOffloadingConfigQuery,
  //   undefined,
  // );
  const { data: dictionaries } = useConvertGeneratedQueryToSuspense(
    useGetDictionariesQuery,
    undefined,
  );
  const tracksList = dictionaries.getDictionaries.tracks;

  const availableNewTrackNames = useMemo(() => {
    return tracksList.filter((tr) => {
      const tracksInUse =
        offloadingConfigsList
          .find((c) => c.country === country)
          ?.experimentConfigs.map((cfg) => cfg.experimentTrack) ?? [];
      return !tracksInUse.includes(tr);
    });
  }, [tracksList, offloadingConfigsList, country]);

  const handleCloneTrack: FormProps<FieldType>['onFinish'] = (values) => {
    const trackData = {
      country: country,
      track: trackConfig.experimentTrack,
      newTrackName: values.experimentTrack,
    };
    cloneTrack(trackData);
    setIsClonePopoverOpen(false);
    // hack to quickly close source track after cloning it
    const currentCollapseHeader = document.querySelector(
      `[data-qa-selector=trackCollapseItem-${index}] .price-management-config-mfe-antd-collapse-header`,
    );
    if (currentCollapseHeader) {
      (currentCollapseHeader as HTMLDivElement).click();
    }
  };

  const renderColumn = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    val: any,
    _: OffloadingPriceClusterConfig,
    idx: number,
    formFieldName: FieldPath<
      OffloadingConfigSchemaType['priceClusterConfigs'][number]
    >,
  ) => {
    if (isEditMode) {
      return (
        <FormItem
          control={methods.control}
          name={`priceClusterConfigs.${idx}.${formFieldName}`}
          required
        >
          <InputNumber
            controls={false}
            min={formFieldName === 'grossProfitThreshold' ? -Infinity : 0}
            max={formFieldName === 'grossProfitThreshold' ? Infinity : 999}
            size="small"
            data-qa-selector="ratioValueInput"
          />
        </FormItem>
      );
    } else {
      return <span data-qa-selector={`${formFieldName}-${idx}`}>{val}</span>;
    }
  };

  const columns: TableColumnType<OffloadingPriceClusterConfig>[] = [
    {
      title: 'Price Cluster',
      key: 'priceCluster',
      dataIndex: 'priceCluster',
      render: (value, _, idx) => (
        <b data-qa-selector={`cluster-${idx}`}>{value}</b>
      ),
    },
    {
      title: 'Grosss profit threshold',
      key: 'grossProfitThreshold',
      dataIndex: 'grossProfitThreshold',
      render: (val, rec, idx) =>
        renderColumn(val, rec, idx, 'grossProfitThreshold'),
    },
    {
      title: 'Platform days threshold',
      key: 'platformDaysThreshold',
      dataIndex: 'platformDaysThreshold',
      render: (val, rec, idx) =>
        renderColumn(val, rec, idx, 'platformDaysThreshold'),
    },
    {
      title: 'Stock days threshold',
      key: 'stockDaysThreshold',
      dataIndex: 'stockDaysThreshold',
      render: (val, rec, idx) =>
        renderColumn(val, rec, idx, 'stockDaysThreshold'),
    },
    {
      title: 'Lag days',
      key: 'lagDays',
      dataIndex: 'lagDays',
      render: (val, rec, idx) => renderColumn(val, rec, idx, 'lagDays'),
    },
  ];

  return (
    <>
      <HStack className={css({ mb: '15px' })}>
        <Button
          type="primary"
          data-qa-selector="editTrackConfigButton"
          disabled={isEditMode || isFetching || isMutating}
          onClick={() => {
            setEditMode(true);
            setEditedTracksCount((c) => c + 1);
            methods.reset({
              priceClusterConfigs: trackConfig.priceClusterConfigs,
            });
          }}
        >
          <EditOutlined /> Edit Track config
        </Button>
        <Button
          type="primary"
          data-qa-selector="editTrackConfigButton"
          disabled={!isEditMode || !methods.formState.isDirty}
          onClick={() => {
            if (Object.values(methods.formState.errors).length === 0) {
              setTrackConfigData({
                country,
                track: trackConfig.experimentTrack,
                priceClusterConfigs: methods.getValues().priceClusterConfigs,
              });
              setEditMode(false);
              setEditedTracksCount((c) => c - 1);
            }
          }}
        >
          <CheckOutlined /> Set track config
        </Button>
        <Button
          type="default"
          data-qa-selector="cancelEditTrackConfigButton"
          disabled={!isEditMode}
          onClick={() => {
            methods.reset({
              priceClusterConfigs: trackConfig.priceClusterConfigs,
            });
            // methods.reset({ priceClusterConfigs: offloadingQuery.data.getOffloadingConfig.find(c => c.country === country)?.experimentConfigs.find(tr => tr.experimentTrack === trackConfig.experimentTrack)?.priceClusterConfigs ?? [] });
            setEditMode(false);
            setEditedTracksCount((c) => c - 1);
          }}
        >
          Discard changes
        </Button>
        <Popover
          destroyTooltipOnHide={true}
          content={
            <>
              Select new Track name:
              <Form name="selectTrackClone" onFinish={handleCloneTrack}>
                <Form.Item<FieldType>
                  name="experimentTrack"
                  rules={[{ required: true, message: 'Please select track!' }]}
                >
                  <Select
                    options={availableNewTrackNames.map((i) => ({
                      label: i,
                      value: i,
                    }))}
                    data-qa-selector="trackCloneSelect"
                  ></Select>
                </Form.Item>
                <Button type="primary" htmlType="submit">
                  Clone
                </Button>
              </Form>
            </>
          }
          title="Clone Track config"
          trigger="click"
          open={isClonePopoverOpen}
          onOpenChange={setIsClonePopoverOpen}
        >
          <Button
            type="default"
            data-qa-selector="cloneTrackConfigButton"
            disabled={isEditMode}
          >
            <CopyOutlined /> Clone Track config
          </Button>
        </Popover>
      </HStack>
      <FormProvider {...methods}>
        <Form
          layout="vertical"
          data-qa-selector="offloadingConfigFormWrapper"
          noValidate={true}
        >
          <Table<(typeof trackConfig.priceClusterConfigs)[number]>
            dataSource={trackConfig.priceClusterConfigs}
            data-qa-selector="offloadingConfigDataTable"
            size="small"
            columns={columns}
            pagination={false}
            tableLayout="fixed"
            rowKey={(record) => record.priceCluster}
          />
        </Form>
      </FormProvider>
    </>
  );
};
