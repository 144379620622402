import { TrackDistributionConfigs } from '@features/OverviewPage/TrackDistributionConfig';
import {
  useGetDictionariesQuery,
  useGetDistributionsQuery,
} from '@gql_codegen/price-management-config-types';
import { createFileRoute } from '@tanstack/react-router';
import { ErrorComponent } from '@src/shared/components/RouteError';
import { notification } from 'antd';
import { formatServerError } from '@src/shared/formating/format-server-error';

const TrackDistributionRouteComponent = () => {
  return <TrackDistributionConfigs />;
};

export const Route = createFileRoute(
  '/$lang/price-management-config/track-distribution-config/',
)({
  component: TrackDistributionRouteComponent,
  loader: ({ context: { queryClient } }) => {
    void Promise.all([
      queryClient.ensureQueryData({
        revalidateIfStale: true,
        queryFn: useGetDistributionsQuery.fetcher(),
        queryKey: useGetDistributionsQuery.getKey(),
      }),
      queryClient.ensureQueryData({
        queryFn: useGetDictionariesQuery.fetcher(),
        queryKey: useGetDictionariesQuery.getKey(),
      }),
    ]);
  },
  errorComponent: ErrorComponent,
  onCatch: (error) => {
    notification.error({
      message: 'Failed to load page',
      description: formatServerError(error),
    });
  },
});
