import { z } from 'zod';

export const OffloadingConfigSchema = z.object({
  priceClusterConfigs: z.array(
    z.object({
      priceCluster: z.string(),
      grossProfitThreshold: z.number().int(),
      platformDaysThreshold: z.number().int(),
      stockDaysThreshold: z.number().int(),
      lagDays: z.number().int(),
    }),
  ),
});

export type OffloadingConfigSchemaType = z.infer<typeof OffloadingConfigSchema>;
