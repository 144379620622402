// THIS FILE IS GENERATED, DO NOT EDIT!
/* istanbul ignore next */
/* eslint-disable */
/* @ts-nocheck */
import type { FAIL_RESPONSE } from '@gql_codegen/typings/api/api_fail';
import type { AxiosError } from 'axios';

import { z } from 'zod';
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from '@tanstack/react-query';
import { api } from './api';
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  UUID: { input: string; output: string };
};

export type TrackDistributionConfigUpdateRequest = {
  country: Scalars['String']['input'];
  priceCluster: Scalars['String']['input'];
  experimentName: Scalars['String']['input'];
  track: Scalars['String']['input'];
  distributionRatio: Scalars['Int']['input'];
};

export type DownPricingCountryConfigInput = {
  country: Scalars['String']['input'];
  priceClusterConfigs: Array<DownPricingClusterConfigInput>;
};

export type DownPricingClusterConfigInput = {
  priceCluster: Scalars['String']['input'];
  trackConfigs: Array<DownPricingTrackConfigInput>;
};

export type DownPricingTrackConfigInput = {
  track: Scalars['String']['input'];
  phaseConfigs: DownPricingPhaseConfigsInput;
};

export type DownPricingPhaseConfigsInput = {
  fresh: DownPricingPhaseConfigInput;
  turnover1: DownPricingPhaseConfigInput;
  turnover2: DownPricingPhaseConfigInput;
  offloading: DownPricingPhaseConfigInput;
};

export type DownPricingPhaseConfigInput = {
  minOnlineDays: Scalars['Int']['input'];
  maxOnlineDays: Scalars['Int']['input'];
  lagDays: Scalars['Int']['input'];
  killSwitchDays: Scalars['Int']['input'];
  ignoreOpenOpportunities: Scalars['Boolean']['input'];
  targetPriceRatio: Scalars['Int']['input'];
  strategyConfigs: Array<DownPricingStrategyConfigInput>;
};

export type DownPricingStrategyConfigInput = {
  strategy: Scalars['String']['input'];
  deltaConfigs: DownPricingDeltaConfigsInput;
};

export type DownPricingDeltaConfigsInput = {
  q1Config: DownPricingDeltaConfigInput;
  q2Config: DownPricingDeltaConfigInput;
  q3Config: DownPricingDeltaConfigInput;
  q4Config: DownPricingDeltaConfigInput;
};

export type DownPricingDeltaConfigInput = {
  percentage?: InputMaybe<DownPricingPercentageDeltaConfigInput>;
  carvana?: InputMaybe<DownPricingCarvanaDeltaConfigInput>;
};

export type DownPricingPercentageDeltaConfigInput = {
  deltaPercentage: Scalars['Float']['input'];
};

export type DownPricingCarvanaDeltaConfigInput = {
  deltaAmount1: Scalars['Int']['input'];
  deltaAmount2: Scalars['Int']['input'];
};

export type OffloadingPriceClusterConfigInput = {
  priceCluster: Scalars['String']['input'];
  grossProfitThreshold: Scalars['Int']['input'];
  platformDaysThreshold: Scalars['Int']['input'];
  stockDaysThreshold: Scalars['Int']['input'];
  lagDays: Scalars['Int']['input'];
};

export type OffloadingExperimentConfigInput = {
  experimentTrack: Scalars['String']['input'];
  priceClusterConfigs: Array<OffloadingPriceClusterConfigInput>;
};

export type OffloadingConfigInput = {
  country: Scalars['String']['input'];
  experimentConfigs: Array<OffloadingExperimentConfigInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  updateDistributions: Array<CountryClusterDistributionConfigs>;
  updateDownPricingConfigs: Scalars['Boolean']['output'];
  updateOffloadingConfig: Scalars['Boolean']['output'];
};

export type MutationUpdateDistributionsArgs = {
  input: Array<TrackDistributionConfigUpdateRequest>;
};

export type MutationUpdateDownPricingConfigsArgs = {
  input: DownPricingCountryConfigInput;
};

export type MutationUpdateOffloadingConfigArgs = {
  input: OffloadingConfigInput;
};

export type Query = {
  __typename?: 'Query';
  getDictionaries: Dictionaries;
  getDistributions: Array<CountryClusterDistributionConfigs>;
  getDownPricingConfigs: Array<DownPricingCountryConfigs>;
  getOffloadingConfig: Array<OffloadingCountryConfig>;
};

export type Dictionaries = {
  __typename?: 'Dictionaries';
  countries: Array<Scalars['String']['output']>;
  priceClusters: Array<Scalars['String']['output']>;
  experimentNames: Array<Scalars['String']['output']>;
  tracks: Array<Scalars['String']['output']>;
};

export type TrackDistributionConfig = {
  __typename?: 'TrackDistributionConfig';
  experimentName: Scalars['String']['output'];
  track: Scalars['String']['output'];
  distributionRatio: Scalars['Int']['output'];
  sourcingType: Scalars['String']['output'];
  countOfAssignedAds: Scalars['Int']['output'];
  examplesOfAssignedAds: Array<Ad>;
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
};

export type ClusterDistributionConfigs = {
  __typename?: 'ClusterDistributionConfigs';
  priceCluster: Scalars['String']['output'];
  configs: Array<TrackDistributionConfig>;
};

export type CountryClusterDistributionConfigs = {
  __typename?: 'CountryClusterDistributionConfigs';
  country: Scalars['String']['output'];
  priceClusters: Array<ClusterDistributionConfigs>;
};

export type Ad = {
  __typename?: 'Ad';
  label: Scalars['String']['output'];
  adId: Scalars['UUID']['output'];
};

export type OffloadingCountryConfig = {
  __typename?: 'OffloadingCountryConfig';
  country: Scalars['String']['output'];
  experimentConfigs: Array<OffloadingExperimentConfig>;
};

export type OffloadingExperimentConfig = {
  __typename?: 'OffloadingExperimentConfig';
  experimentTrack: Scalars['String']['output'];
  priceClusterConfigs: Array<OffloadingPriceClusterConfig>;
};

export type OffloadingPriceClusterConfig = {
  __typename?: 'OffloadingPriceClusterConfig';
  priceCluster: Scalars['String']['output'];
  grossProfitThreshold: Scalars['Int']['output'];
  platformDaysThreshold: Scalars['Int']['output'];
  stockDaysThreshold: Scalars['Int']['output'];
  lagDays: Scalars['Int']['output'];
};

export type DownPricingCountryConfigs = {
  __typename?: 'DownPricingCountryConfigs';
  country: Scalars['String']['output'];
  priceClusterConfigs: Array<DownPricingClusterConfigs>;
};

export type DownPricingClusterConfigs = {
  __typename?: 'DownPricingClusterConfigs';
  priceCluster: Scalars['String']['output'];
  trackConfigs: Array<DownPricingTrackConfig>;
};

export type DownPricingTrackConfig = {
  __typename?: 'DownPricingTrackConfig';
  track: Scalars['String']['output'];
  phaseConfigs: DownPricingPhaseConfigs;
};

export type DownPricingPhaseConfigs = {
  __typename?: 'DownPricingPhaseConfigs';
  fresh: DownPricingPhaseConfig;
  turnover1: DownPricingPhaseConfig;
  turnover2: DownPricingPhaseConfig;
  offloading: DownPricingPhaseConfig;
};

export type DownPricingPhaseConfig = {
  __typename?: 'DownPricingPhaseConfig';
  minOnlineDays: Scalars['Int']['output'];
  maxOnlineDays: Scalars['Int']['output'];
  lagDays: Scalars['Int']['output'];
  killSwitchDays: Scalars['Int']['output'];
  ignoreOpenOpportunities: Scalars['Boolean']['output'];
  targetPriceRatio: Scalars['Int']['output'];
  strategyConfigs: Array<DownPricingStrategyConfig>;
};

export type DownPricingStrategyConfig = {
  __typename?: 'DownPricingStrategyConfig';
  strategy: Scalars['String']['output'];
  deltaConfigs: DownPricingDeltaConfigs;
};

export type DownPricingDeltaConfigs = {
  __typename?: 'DownPricingDeltaConfigs';
  q1Config: DownPricingDeltaConfig;
  q2Config: DownPricingDeltaConfig;
  q3Config: DownPricingDeltaConfig;
  q4Config: DownPricingDeltaConfig;
};

export type DownPricingDeltaConfig =
  | DownPricingPercentageDeltaConfig
  | DownPricingCarvanaDeltaConfig;

export type DownPricingPercentageDeltaConfig = {
  __typename?: 'DownPricingPercentageDeltaConfig';
  deltaPercentage: Scalars['Float']['output'];
};

export type DownPricingCarvanaDeltaConfig = {
  __typename?: 'DownPricingCarvanaDeltaConfig';
  deltaAmount1: Scalars['Int']['output'];
  deltaAmount2: Scalars['Int']['output'];
};

export type GetOffloadingConfigQueryVariables = Exact<{ [key: string]: never }>;

export type GetOffloadingConfigQuery = {
  __typename?: 'Query';
  getOffloadingConfig: Array<{
    __typename?: 'OffloadingCountryConfig';
    country: string;
    experimentConfigs: Array<{
      __typename?: 'OffloadingExperimentConfig';
      experimentTrack: string;
      priceClusterConfigs: Array<{
        __typename?: 'OffloadingPriceClusterConfig';
        priceCluster: string;
        grossProfitThreshold: number;
        platformDaysThreshold: number;
        stockDaysThreshold: number;
        lagDays: number;
      }>;
    }>;
  }>;
};

export type GetDownPricingConfigsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetDownPricingConfigsQuery = {
  __typename?: 'Query';
  getDownPricingConfigs: Array<{
    __typename?: 'DownPricingCountryConfigs';
    country: string;
    priceClusterConfigs: Array<{
      __typename?: 'DownPricingClusterConfigs';
      priceCluster: string;
      trackConfigs: Array<{
        __typename?: 'DownPricingTrackConfig';
        track: string;
        phaseConfigs: {
          __typename?: 'DownPricingPhaseConfigs';
          fresh: {
            __typename?: 'DownPricingPhaseConfig';
            minOnlineDays: number;
            maxOnlineDays: number;
            lagDays: number;
            killSwitchDays: number;
            ignoreOpenOpportunities: boolean;
            targetPriceRatio: number;
            strategyConfigs: Array<{
              __typename?: 'DownPricingStrategyConfig';
              strategy: string;
              deltaConfigs: {
                __typename?: 'DownPricingDeltaConfigs';
                q1Config:
                  | {
                      __typename?: 'DownPricingPercentageDeltaConfig';
                      deltaPercentage: number;
                    }
                  | {
                      __typename?: 'DownPricingCarvanaDeltaConfig';
                      deltaAmount1: number;
                      deltaAmount2: number;
                    };
                q2Config:
                  | {
                      __typename?: 'DownPricingPercentageDeltaConfig';
                      deltaPercentage: number;
                    }
                  | {
                      __typename?: 'DownPricingCarvanaDeltaConfig';
                      deltaAmount1: number;
                      deltaAmount2: number;
                    };
                q3Config:
                  | {
                      __typename?: 'DownPricingPercentageDeltaConfig';
                      deltaPercentage: number;
                    }
                  | {
                      __typename?: 'DownPricingCarvanaDeltaConfig';
                      deltaAmount1: number;
                      deltaAmount2: number;
                    };
                q4Config:
                  | {
                      __typename?: 'DownPricingPercentageDeltaConfig';
                      deltaPercentage: number;
                    }
                  | {
                      __typename?: 'DownPricingCarvanaDeltaConfig';
                      deltaAmount1: number;
                      deltaAmount2: number;
                    };
              };
            }>;
          };
          turnover1: {
            __typename?: 'DownPricingPhaseConfig';
            minOnlineDays: number;
            maxOnlineDays: number;
            lagDays: number;
            killSwitchDays: number;
            ignoreOpenOpportunities: boolean;
            targetPriceRatio: number;
            strategyConfigs: Array<{
              __typename?: 'DownPricingStrategyConfig';
              strategy: string;
              deltaConfigs: {
                __typename?: 'DownPricingDeltaConfigs';
                q1Config:
                  | {
                      __typename?: 'DownPricingPercentageDeltaConfig';
                      deltaPercentage: number;
                    }
                  | {
                      __typename?: 'DownPricingCarvanaDeltaConfig';
                      deltaAmount1: number;
                      deltaAmount2: number;
                    };
                q2Config:
                  | {
                      __typename?: 'DownPricingPercentageDeltaConfig';
                      deltaPercentage: number;
                    }
                  | {
                      __typename?: 'DownPricingCarvanaDeltaConfig';
                      deltaAmount1: number;
                      deltaAmount2: number;
                    };
                q3Config:
                  | {
                      __typename?: 'DownPricingPercentageDeltaConfig';
                      deltaPercentage: number;
                    }
                  | {
                      __typename?: 'DownPricingCarvanaDeltaConfig';
                      deltaAmount1: number;
                      deltaAmount2: number;
                    };
                q4Config:
                  | {
                      __typename?: 'DownPricingPercentageDeltaConfig';
                      deltaPercentage: number;
                    }
                  | {
                      __typename?: 'DownPricingCarvanaDeltaConfig';
                      deltaAmount1: number;
                      deltaAmount2: number;
                    };
              };
            }>;
          };
          turnover2: {
            __typename?: 'DownPricingPhaseConfig';
            minOnlineDays: number;
            maxOnlineDays: number;
            lagDays: number;
            killSwitchDays: number;
            ignoreOpenOpportunities: boolean;
            targetPriceRatio: number;
            strategyConfigs: Array<{
              __typename?: 'DownPricingStrategyConfig';
              strategy: string;
              deltaConfigs: {
                __typename?: 'DownPricingDeltaConfigs';
                q1Config:
                  | {
                      __typename?: 'DownPricingPercentageDeltaConfig';
                      deltaPercentage: number;
                    }
                  | {
                      __typename?: 'DownPricingCarvanaDeltaConfig';
                      deltaAmount1: number;
                      deltaAmount2: number;
                    };
                q2Config:
                  | {
                      __typename?: 'DownPricingPercentageDeltaConfig';
                      deltaPercentage: number;
                    }
                  | {
                      __typename?: 'DownPricingCarvanaDeltaConfig';
                      deltaAmount1: number;
                      deltaAmount2: number;
                    };
                q3Config:
                  | {
                      __typename?: 'DownPricingPercentageDeltaConfig';
                      deltaPercentage: number;
                    }
                  | {
                      __typename?: 'DownPricingCarvanaDeltaConfig';
                      deltaAmount1: number;
                      deltaAmount2: number;
                    };
                q4Config:
                  | {
                      __typename?: 'DownPricingPercentageDeltaConfig';
                      deltaPercentage: number;
                    }
                  | {
                      __typename?: 'DownPricingCarvanaDeltaConfig';
                      deltaAmount1: number;
                      deltaAmount2: number;
                    };
              };
            }>;
          };
          offloading: {
            __typename?: 'DownPricingPhaseConfig';
            minOnlineDays: number;
            maxOnlineDays: number;
            lagDays: number;
            killSwitchDays: number;
            ignoreOpenOpportunities: boolean;
            targetPriceRatio: number;
            strategyConfigs: Array<{
              __typename?: 'DownPricingStrategyConfig';
              strategy: string;
              deltaConfigs: {
                __typename?: 'DownPricingDeltaConfigs';
                q1Config:
                  | {
                      __typename?: 'DownPricingPercentageDeltaConfig';
                      deltaPercentage: number;
                    }
                  | {
                      __typename?: 'DownPricingCarvanaDeltaConfig';
                      deltaAmount1: number;
                      deltaAmount2: number;
                    };
                q2Config:
                  | {
                      __typename?: 'DownPricingPercentageDeltaConfig';
                      deltaPercentage: number;
                    }
                  | {
                      __typename?: 'DownPricingCarvanaDeltaConfig';
                      deltaAmount1: number;
                      deltaAmount2: number;
                    };
                q3Config:
                  | {
                      __typename?: 'DownPricingPercentageDeltaConfig';
                      deltaPercentage: number;
                    }
                  | {
                      __typename?: 'DownPricingCarvanaDeltaConfig';
                      deltaAmount1: number;
                      deltaAmount2: number;
                    };
                q4Config:
                  | {
                      __typename?: 'DownPricingPercentageDeltaConfig';
                      deltaPercentage: number;
                    }
                  | {
                      __typename?: 'DownPricingCarvanaDeltaConfig';
                      deltaAmount1: number;
                      deltaAmount2: number;
                    };
              };
            }>;
          };
        };
      }>;
    }>;
  }>;
};

export type GetDistributionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetDistributionsQuery = {
  __typename?: 'Query';
  getDistributions: Array<{
    __typename?: 'CountryClusterDistributionConfigs';
    country: string;
    priceClusters: Array<{
      __typename?: 'ClusterDistributionConfigs';
      priceCluster: string;
      configs: Array<{
        __typename?: 'TrackDistributionConfig';
        experimentName: string;
        track: string;
        distributionRatio: number;
        createdAt: string;
        createdBy: string;
        sourcingType: string;
        countOfAssignedAds: number;
        examplesOfAssignedAds: Array<{
          __typename?: 'Ad';
          label: string;
          adId: string;
        }>;
      }>;
    }>;
  }>;
};

export type GetDictionariesQueryVariables = Exact<{ [key: string]: never }>;

export type GetDictionariesQuery = {
  __typename?: 'Query';
  getDictionaries: {
    __typename?: 'Dictionaries';
    countries: Array<string>;
    priceClusters: Array<string>;
    experimentNames: Array<string>;
    tracks: Array<string>;
  };
};

export type UpdateOffloadingConfigMutationVariables = Exact<{
  input: OffloadingConfigInput;
}>;

export type UpdateOffloadingConfigMutation = {
  __typename?: 'Mutation';
  updateOffloadingConfig: boolean;
};

export type UpdateDownPricingConfigsMutationVariables = Exact<{
  input: DownPricingCountryConfigInput;
}>;

export type UpdateDownPricingConfigsMutation = {
  __typename?: 'Mutation';
  updateDownPricingConfigs: boolean;
};

export type UpdateDistributionsMutationVariables = Exact<{
  input:
    | Array<TrackDistributionConfigUpdateRequest>
    | TrackDistributionConfigUpdateRequest;
}>;

export type UpdateDistributionsMutation = {
  __typename?: 'Mutation';
  updateDistributions: Array<{
    __typename?: 'CountryClusterDistributionConfigs';
    country: string;
    priceClusters: Array<{
      __typename?: 'ClusterDistributionConfigs';
      priceCluster: string;
      configs: Array<{
        __typename?: 'TrackDistributionConfig';
        track: string;
        experimentName: string;
        distributionRatio: number;
        sourcingType: string;
        createdAt: string;
        createdBy: string;
      }>;
    }>;
  }>;
};

export type PhaseConfig_FragmentFragment = {
  __typename?: 'DownPricingPhaseConfig';
  minOnlineDays: number;
  maxOnlineDays: number;
  lagDays: number;
  killSwitchDays: number;
  ignoreOpenOpportunities: boolean;
  targetPriceRatio: number;
  strategyConfigs: Array<{
    __typename?: 'DownPricingStrategyConfig';
    strategy: string;
    deltaConfigs: {
      __typename?: 'DownPricingDeltaConfigs';
      q1Config:
        | {
            __typename?: 'DownPricingPercentageDeltaConfig';
            deltaPercentage: number;
          }
        | {
            __typename?: 'DownPricingCarvanaDeltaConfig';
            deltaAmount1: number;
            deltaAmount2: number;
          };
      q2Config:
        | {
            __typename?: 'DownPricingPercentageDeltaConfig';
            deltaPercentage: number;
          }
        | {
            __typename?: 'DownPricingCarvanaDeltaConfig';
            deltaAmount1: number;
            deltaAmount2: number;
          };
      q3Config:
        | {
            __typename?: 'DownPricingPercentageDeltaConfig';
            deltaPercentage: number;
          }
        | {
            __typename?: 'DownPricingCarvanaDeltaConfig';
            deltaAmount1: number;
            deltaAmount2: number;
          };
      q4Config:
        | {
            __typename?: 'DownPricingPercentageDeltaConfig';
            deltaPercentage: number;
          }
        | {
            __typename?: 'DownPricingCarvanaDeltaConfig';
            deltaAmount1: number;
            deltaAmount2: number;
          };
    };
  }>;
};

export const PhaseConfig_FragmentFragmentDoc = `
    fragment PhaseConfig_Fragment on DownPricingPhaseConfig {
  minOnlineDays
  maxOnlineDays
  lagDays
  killSwitchDays
  ignoreOpenOpportunities
  targetPriceRatio
  strategyConfigs {
    strategy
    deltaConfigs {
      q1Config {
        ... on DownPricingPercentageDeltaConfig {
          deltaPercentage
        }
        ... on DownPricingCarvanaDeltaConfig {
          deltaAmount1
          deltaAmount2
        }
      }
      q2Config {
        ... on DownPricingPercentageDeltaConfig {
          deltaPercentage
        }
        ... on DownPricingCarvanaDeltaConfig {
          deltaAmount1
          deltaAmount2
        }
      }
      q3Config {
        ... on DownPricingPercentageDeltaConfig {
          deltaPercentage
        }
        ... on DownPricingCarvanaDeltaConfig {
          deltaAmount1
          deltaAmount2
        }
      }
      q4Config {
        ... on DownPricingPercentageDeltaConfig {
          deltaPercentage
        }
        ... on DownPricingCarvanaDeltaConfig {
          deltaAmount1
          deltaAmount2
        }
      }
    }
  }
}
    `;
export const GetOffloadingConfigDocument = `
    query getOffloadingConfig {
  getOffloadingConfig {
    country
    experimentConfigs {
      experimentTrack
      priceClusterConfigs {
        priceCluster
        grossProfitThreshold
        platformDaysThreshold
        stockDaysThreshold
        lagDays
      }
    }
  }
}
    `;

export const useGetOffloadingConfigQuery = <
  TData = GetOffloadingConfigQuery,
  TError = FAIL_RESPONSE | AxiosError<FAIL_RESPONSE>,
>(
  variables?: GetOffloadingConfigQueryVariables,
  options?: Omit<
    UseQueryOptions<GetOffloadingConfigQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      GetOffloadingConfigQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  return useQuery<GetOffloadingConfigQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['getOffloadingConfig']
        : ['getOffloadingConfig', variables],
    queryFn: api<GetOffloadingConfigQuery, GetOffloadingConfigQueryVariables>(
      GetOffloadingConfigDocument,
      variables,
    ),
    ...options,
  });
};

useGetOffloadingConfigQuery.getKey = (
  variables?: GetOffloadingConfigQueryVariables,
) =>
  variables === undefined
    ? ['getOffloadingConfig']
    : ['getOffloadingConfig', variables];

useGetOffloadingConfigQuery.fetcher = (
  variables?: GetOffloadingConfigQueryVariables,
  options?: RequestInit['headers'],
) =>
  api<GetOffloadingConfigQuery, GetOffloadingConfigQueryVariables>(
    GetOffloadingConfigDocument,
    variables,
    options,
  );

export const GetDownPricingConfigsDocument = `
    query getDownPricingConfigs {
  getDownPricingConfigs {
    country
    priceClusterConfigs {
      priceCluster
      trackConfigs {
        track
        phaseConfigs {
          fresh {
            ...PhaseConfig_Fragment
          }
          turnover1 {
            ...PhaseConfig_Fragment
          }
          turnover2 {
            ...PhaseConfig_Fragment
          }
          offloading {
            ...PhaseConfig_Fragment
          }
        }
      }
    }
  }
}
    ${PhaseConfig_FragmentFragmentDoc}`;

export const useGetDownPricingConfigsQuery = <
  TData = GetDownPricingConfigsQuery,
  TError = FAIL_RESPONSE | AxiosError<FAIL_RESPONSE>,
>(
  variables?: GetDownPricingConfigsQueryVariables,
  options?: Omit<
    UseQueryOptions<GetDownPricingConfigsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      GetDownPricingConfigsQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  return useQuery<GetDownPricingConfigsQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['getDownPricingConfigs']
        : ['getDownPricingConfigs', variables],
    queryFn: api<
      GetDownPricingConfigsQuery,
      GetDownPricingConfigsQueryVariables
    >(GetDownPricingConfigsDocument, variables),
    ...options,
  });
};

useGetDownPricingConfigsQuery.getKey = (
  variables?: GetDownPricingConfigsQueryVariables,
) =>
  variables === undefined
    ? ['getDownPricingConfigs']
    : ['getDownPricingConfigs', variables];

useGetDownPricingConfigsQuery.fetcher = (
  variables?: GetDownPricingConfigsQueryVariables,
  options?: RequestInit['headers'],
) =>
  api<GetDownPricingConfigsQuery, GetDownPricingConfigsQueryVariables>(
    GetDownPricingConfigsDocument,
    variables,
    options,
  );

export const GetDistributionsDocument = `
    query getDistributions {
  getDistributions {
    country
    priceClusters {
      priceCluster
      configs {
        experimentName
        track
        distributionRatio
        createdAt
        createdBy
        sourcingType
        countOfAssignedAds
        examplesOfAssignedAds {
          label
          adId
        }
      }
    }
  }
}
    `;

export const useGetDistributionsQuery = <
  TData = GetDistributionsQuery,
  TError = FAIL_RESPONSE | AxiosError<FAIL_RESPONSE>,
>(
  variables?: GetDistributionsQueryVariables,
  options?: Omit<
    UseQueryOptions<GetDistributionsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      GetDistributionsQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  return useQuery<GetDistributionsQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['getDistributions']
        : ['getDistributions', variables],
    queryFn: api<GetDistributionsQuery, GetDistributionsQueryVariables>(
      GetDistributionsDocument,
      variables,
    ),
    ...options,
  });
};

useGetDistributionsQuery.getKey = (
  variables?: GetDistributionsQueryVariables,
) =>
  variables === undefined
    ? ['getDistributions']
    : ['getDistributions', variables];

useGetDistributionsQuery.fetcher = (
  variables?: GetDistributionsQueryVariables,
  options?: RequestInit['headers'],
) =>
  api<GetDistributionsQuery, GetDistributionsQueryVariables>(
    GetDistributionsDocument,
    variables,
    options,
  );

export const GetDictionariesDocument = `
    query getDictionaries {
  getDictionaries {
    countries
    priceClusters
    experimentNames
    tracks
  }
}
    `;

export const useGetDictionariesQuery = <
  TData = GetDictionariesQuery,
  TError = FAIL_RESPONSE | AxiosError<FAIL_RESPONSE>,
>(
  variables?: GetDictionariesQueryVariables,
  options?: Omit<
    UseQueryOptions<GetDictionariesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<GetDictionariesQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetDictionariesQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['getDictionaries']
        : ['getDictionaries', variables],
    queryFn: api<GetDictionariesQuery, GetDictionariesQueryVariables>(
      GetDictionariesDocument,
      variables,
    ),
    ...options,
  });
};

useGetDictionariesQuery.getKey = (variables?: GetDictionariesQueryVariables) =>
  variables === undefined
    ? ['getDictionaries']
    : ['getDictionaries', variables];

useGetDictionariesQuery.fetcher = (
  variables?: GetDictionariesQueryVariables,
  options?: RequestInit['headers'],
) =>
  api<GetDictionariesQuery, GetDictionariesQueryVariables>(
    GetDictionariesDocument,
    variables,
    options,
  );

export const UpdateOffloadingConfigDocument = `
    mutation updateOffloadingConfig($input: OffloadingConfigInput!) {
  updateOffloadingConfig(input: $input)
}
    `;

export const useUpdateOffloadingConfigMutation = <
  TError = FAIL_RESPONSE | AxiosError<FAIL_RESPONSE>,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateOffloadingConfigMutation,
    TError,
    UpdateOffloadingConfigMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    UpdateOffloadingConfigMutation,
    TError,
    UpdateOffloadingConfigMutationVariables,
    TContext
  >({
    mutationKey: ['updateOffloadingConfig'],
    mutationFn: (variables?: UpdateOffloadingConfigMutationVariables) =>
      api<
        UpdateOffloadingConfigMutation,
        UpdateOffloadingConfigMutationVariables
      >(UpdateOffloadingConfigDocument, variables)(),
    ...options,
  });
};

useUpdateOffloadingConfigMutation.fetcher = (
  variables: UpdateOffloadingConfigMutationVariables,
  options?: RequestInit['headers'],
) =>
  api<UpdateOffloadingConfigMutation, UpdateOffloadingConfigMutationVariables>(
    UpdateOffloadingConfigDocument,
    variables,
    options,
  );

export const UpdateDownPricingConfigsDocument = `
    mutation updateDownPricingConfigs($input: DownPricingCountryConfigInput!) {
  updateDownPricingConfigs(input: $input)
}
    `;

export const useUpdateDownPricingConfigsMutation = <
  TError = FAIL_RESPONSE | AxiosError<FAIL_RESPONSE>,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateDownPricingConfigsMutation,
    TError,
    UpdateDownPricingConfigsMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    UpdateDownPricingConfigsMutation,
    TError,
    UpdateDownPricingConfigsMutationVariables,
    TContext
  >({
    mutationKey: ['updateDownPricingConfigs'],
    mutationFn: (variables?: UpdateDownPricingConfigsMutationVariables) =>
      api<
        UpdateDownPricingConfigsMutation,
        UpdateDownPricingConfigsMutationVariables
      >(UpdateDownPricingConfigsDocument, variables)(),
    ...options,
  });
};

useUpdateDownPricingConfigsMutation.fetcher = (
  variables: UpdateDownPricingConfigsMutationVariables,
  options?: RequestInit['headers'],
) =>
  api<
    UpdateDownPricingConfigsMutation,
    UpdateDownPricingConfigsMutationVariables
  >(UpdateDownPricingConfigsDocument, variables, options);

export const UpdateDistributionsDocument = `
    mutation updateDistributions($input: [TrackDistributionConfigUpdateRequest!]!) {
  updateDistributions(input: $input) {
    country
    priceClusters {
      priceCluster
      configs {
        track
        experimentName
        distributionRatio
        sourcingType
        createdAt
        createdBy
      }
    }
  }
}
    `;

export const useUpdateDistributionsMutation = <
  TError = FAIL_RESPONSE | AxiosError<FAIL_RESPONSE>,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateDistributionsMutation,
    TError,
    UpdateDistributionsMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    UpdateDistributionsMutation,
    TError,
    UpdateDistributionsMutationVariables,
    TContext
  >({
    mutationKey: ['updateDistributions'],
    mutationFn: (variables?: UpdateDistributionsMutationVariables) =>
      api<UpdateDistributionsMutation, UpdateDistributionsMutationVariables>(
        UpdateDistributionsDocument,
        variables,
      )(),
    ...options,
  });
};

useUpdateDistributionsMutation.fetcher = (
  variables: UpdateDistributionsMutationVariables,
  options?: RequestInit['headers'],
) =>
  api<UpdateDistributionsMutation, UpdateDistributionsMutationVariables>(
    UpdateDistributionsDocument,
    variables,
    options,
  );

type Properties<T> = Required<{
  [K in keyof T]: z.ZodType<T[K], any, T[K]>;
}>;

type definedNonNullAny = {};

export const isDefinedNonNullAny = (v: any): v is definedNonNullAny =>
  v !== undefined && v !== null;

export const definedNonNullAnySchema = z
  .any()
  .refine((v) => isDefinedNonNullAny(v));

export function TrackDistributionConfigUpdateRequestSchema(): z.ZodObject<
  Properties<TrackDistributionConfigUpdateRequest>
> {
  return z.object({
    country: z.string(),
    priceCluster: z.string(),
    experimentName: z.string(),
    track: z.string(),
    distributionRatio: z.number().int(),
  });
}

export function DownPricingCountryConfigInputSchema(): z.ZodObject<
  Properties<DownPricingCountryConfigInput>
> {
  return z.object({
    country: z.string(),
    priceClusterConfigs: z.array(
      z.lazy(() => DownPricingClusterConfigInputSchema()),
    ),
  });
}

export function DownPricingClusterConfigInputSchema(): z.ZodObject<
  Properties<DownPricingClusterConfigInput>
> {
  return z.object({
    priceCluster: z.string(),
    trackConfigs: z.array(z.lazy(() => DownPricingTrackConfigInputSchema())),
  });
}

export function DownPricingTrackConfigInputSchema(): z.ZodObject<
  Properties<DownPricingTrackConfigInput>
> {
  return z.object({
    track: z.string(),
    phaseConfigs: z.lazy(() => DownPricingPhaseConfigsInputSchema()),
  });
}

export function DownPricingPhaseConfigsInputSchema(): z.ZodObject<
  Properties<DownPricingPhaseConfigsInput>
> {
  return z.object({
    fresh: z.lazy(() => DownPricingPhaseConfigInputSchema()),
    turnover1: z.lazy(() => DownPricingPhaseConfigInputSchema()),
    turnover2: z.lazy(() => DownPricingPhaseConfigInputSchema()),
    offloading: z.lazy(() => DownPricingPhaseConfigInputSchema()),
  });
}

export function DownPricingPhaseConfigInputSchema(): z.ZodObject<
  Properties<DownPricingPhaseConfigInput>
> {
  return z.object({
    minOnlineDays: z.number().int(),
    maxOnlineDays: z.number().int(),
    lagDays: z.number().int(),
    killSwitchDays: z.number().int(),
    ignoreOpenOpportunities: z.boolean(),
    targetPriceRatio: z.number().int(),
    strategyConfigs: z.array(
      z.lazy(() => DownPricingStrategyConfigInputSchema()),
    ),
  });
}

export function DownPricingStrategyConfigInputSchema(): z.ZodObject<
  Properties<DownPricingStrategyConfigInput>
> {
  return z.object({
    strategy: z.string(),
    deltaConfigs: z.lazy(() => DownPricingDeltaConfigsInputSchema()),
  });
}

export function DownPricingDeltaConfigsInputSchema(): z.ZodObject<
  Properties<DownPricingDeltaConfigsInput>
> {
  return z.object({
    q1Config: z.lazy(() => DownPricingDeltaConfigInputSchema()),
    q2Config: z.lazy(() => DownPricingDeltaConfigInputSchema()),
    q3Config: z.lazy(() => DownPricingDeltaConfigInputSchema()),
    q4Config: z.lazy(() => DownPricingDeltaConfigInputSchema()),
  });
}

export function DownPricingDeltaConfigInputSchema(): z.ZodObject<
  Properties<DownPricingDeltaConfigInput>
> {
  return z.object({
    percentage: z.lazy(() =>
      DownPricingPercentageDeltaConfigInputSchema().nullish(),
    ),
    carvana: z.lazy(() => DownPricingCarvanaDeltaConfigInputSchema().nullish()),
  });
}

export function DownPricingPercentageDeltaConfigInputSchema(): z.ZodObject<
  Properties<DownPricingPercentageDeltaConfigInput>
> {
  return z.object({
    deltaPercentage: z.number(),
  });
}

export function DownPricingCarvanaDeltaConfigInputSchema(): z.ZodObject<
  Properties<DownPricingCarvanaDeltaConfigInput>
> {
  return z.object({
    deltaAmount1: z.number().int(),
    deltaAmount2: z.number().int(),
  });
}

export function OffloadingPriceClusterConfigInputSchema(): z.ZodObject<
  Properties<OffloadingPriceClusterConfigInput>
> {
  return z.object({
    priceCluster: z.string(),
    grossProfitThreshold: z.number().int(),
    platformDaysThreshold: z.number().int(),
    stockDaysThreshold: z.number().int(),
    lagDays: z.number().int(),
  });
}

export function OffloadingExperimentConfigInputSchema(): z.ZodObject<
  Properties<OffloadingExperimentConfigInput>
> {
  return z.object({
    experimentTrack: z.string(),
    priceClusterConfigs: z.array(
      z.lazy(() => OffloadingPriceClusterConfigInputSchema()),
    ),
  });
}

export function OffloadingConfigInputSchema(): z.ZodObject<
  Properties<OffloadingConfigInput>
> {
  return z.object({
    country: z.string(),
    experimentConfigs: z.array(
      z.lazy(() => OffloadingExperimentConfigInputSchema()),
    ),
  });
}
