import { ComponentProps, useEffect } from 'react';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { Button } from 'antd';
import { ErrorRouteComponent, useRouter } from '@tanstack/react-router';
import { formatServerError } from '@src/shared/formating/format-server-error';

export const ErrorComponent: ErrorRouteComponent = ({
  error,
}: ComponentProps<ErrorRouteComponent>) => {
  const router = useRouter();
  const queryErrorResetBoundary = useQueryErrorResetBoundary();

  useEffect(() => {
    // Reset the query error boundary
    queryErrorResetBoundary.reset();
  }, [queryErrorResetBoundary]);

  return (
    <div>
      {formatServerError(error)}
      <Button
        onClick={() => {
          // Invalidate the route to reload the loader, and reset any router error boundaries
          void router.invalidate();
        }}
      >
        Retry
      </Button>
    </div>
  );
};
