import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import type {
  OffloadingPriceClusterConfig,
  OffloadingCountryConfig,
} from '@gql_codegen/price-management-config-types';
import { notification } from 'antd';

export type OffloadingTrackConfig = {
  experimentTrack: string;
  priceClusterConfigs: OffloadingPriceClusterConfig[];
  isTrackEdited?: boolean;
};
interface OffloadingStoreType {
  offloadingConfigsList: {
    country: string;
    experimentConfigs: OffloadingTrackConfig[];
    isCountryEdited?: boolean;
  }[];
  setQueryData: (data: OffloadingCountryConfig[]) => void;
  resetIsEditedState: (country: string) => void;
  getCountryData: (country: string) => OffloadingCountryConfig | undefined;
  setTrackConfigData: ({
    country,
    track,
    priceClusterConfigs,
  }: {
    country: string;
    track: string;
    priceClusterConfigs: OffloadingPriceClusterConfig[];
  }) => void;
  cloneTrack: ({
    country,
    track,
    newTrackName,
  }: {
    country: string;
    track: string;
    newTrackName: string;
  }) => void;
}

export const useOffloadingStore = create<OffloadingStoreType>()(
  devtools(
    immer((set, get) => ({
      offloadingConfigsList: [],
      setQueryData: (data) =>
        set((state) => {
          state.offloadingConfigsList = data;
        }),
      getCountryData: (country) =>
        get().offloadingConfigsList.find((c) => c.country === country),
      resetIsEditedState: (country) =>
        set((state) => {
          const foundCountry = state.offloadingConfigsList.find(
            (ctr) => ctr.country === country,
          );
          if (!foundCountry) {
            return notification.error({
              message: 'Country not found',
            });
          }
          foundCountry.isCountryEdited = false;
          foundCountry.experimentConfigs.forEach((tr) => {
            tr.isTrackEdited = false;
          });
        }),
      setTrackConfigData: ({ country, track, priceClusterConfigs }) =>
        set((state) => {
          const foundCountry = state.offloadingConfigsList.find(
            (c) => c.country === country,
          );
          if (!foundCountry)
            return notification.error({
              message: 'Country not found',
            });
          const foundTrack = foundCountry.experimentConfigs.find(
            (t) => t.experimentTrack === track,
          );
          if (!foundTrack)
            return notification.error({
              message: 'Track not found',
            });
          foundTrack.priceClusterConfigs = priceClusterConfigs;
          foundTrack.isTrackEdited = true;
          foundCountry.isCountryEdited = true;
        }),
      cloneTrack: ({ country, track, newTrackName }) =>
        set((state) => {
          const foundCountry = state.offloadingConfigsList.find(
            (c) => c.country === country,
          );
          if (!foundCountry)
            return notification.error({
              message: 'Country not found',
            });

          const foundTrack = foundCountry.experimentConfigs.find(
            (t) => t.experimentTrack === track,
          );
          if (!foundTrack)
            return notification.error({
              message: 'Track not found',
            });
          foundCountry.experimentConfigs = [
            ...foundCountry.experimentConfigs,
            {
              experimentTrack: newTrackName,
              priceClusterConfigs: foundTrack.priceClusterConfigs,
              isTrackEdited: true,
            },
          ].sort((a, b) => {
            if (a.experimentTrack > b.experimentTrack) {
              return 1;
            }
            if (a.experimentTrack < b.experimentTrack) {
              return -1;
            }
            return 0;
          });
          foundCountry.isCountryEdited = true;
        }),
    })),
    {
      name: 'OffloadingStore',
      anonymousActionType: 'OffloadingStoreAction',
    },
  ),
);
