import {
  Table,
  // Divider,
  Button,
  InputNumber,
  Form,
  Switch,
  Popover,
  Select,
  Collapse,
} from 'antd';
import type { FormProps, CollapseProps } from 'antd';

import {
  phasesTableRowConfig,
  TableRowKeys,
  deltasTableRowConfig,
  PhasesKeys,
  DeltasTableRowKeys,
} from './phasesTableConfig';
import { FormItem } from 'react-hook-form-antd';
// import { DevTool } from '@hookform/devtools';
import { FormProvider, useForm, FieldPath } from 'react-hook-form';
import {
  DownpricingConfigSchemaType,
  DownpricingConfigSchema,
} from '../../model/downpricingConfigSchema';
import { DeltaAmount } from './deltaAmount';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';

import { useMemo, useState } from 'react';
import {
  DownPricingPhaseConfigs,
  DownPricingCarvanaDeltaConfig,
  DownPricingPercentageDeltaConfig,
  useGetDictionariesQuery,
} from '@gql_codegen/price-management-config-types';
import { useDownPricingStore } from '../../store/downPricingStore';
import { css } from '@styled-system/css';
import { HStack } from '@styled-system/jsx';
import { EditOutlined, CheckOutlined, CopyOutlined } from '@ant-design/icons';
import { zodResolver } from '@hookform/resolvers/zod';
import { useConvertGeneratedQueryToSuspense } from '@src/shared/hooks/use-convert-generated-query-to-suspence';

const { Column } = Table;

type FieldType = {
  track: string;
};

type PhaseConfigTable = {
  phaseConfigs: DownPricingPhaseConfigs;
  country: string;
  cluster: string;
  track: string;
  index: number;
  setEditedTracksCount: React.Dispatch<React.SetStateAction<number>>;
};
export const PhaseConfigTable: React.FC<PhaseConfigTable> = ({
  phaseConfigs,
  country,
  cluster,
  track,
  index,
  setEditedTracksCount,
}) => {
  const [setTrackConfigData, cloneTrack, downpricingConfigsList] =
    useDownPricingStore((state) => [
      state.setTrackConfigData,
      state.cloneTrack,
      state.downpricingConfigsList,
    ]);

  const [isClonePopoverOpen, setIsClonePopoverOpen] = useState(false);
  const handleCloneTrack: FormProps<FieldType>['onFinish'] = (values) => {
    const trackData = {
      country: country,
      cluster: cluster,
      track: track,
      newTrackName: values.track,
    };
    cloneTrack(trackData);
    setIsClonePopoverOpen(false);
    // hack to quickly close sourcet track after cloning it
    const currentCollapseHeader = document.querySelector(
      `[data-qa-selector=trackCollapseItem-${index}] .price-management-config-mfe-antd-collapse-header`,
    );
    if (currentCollapseHeader) {
      (currentCollapseHeader as HTMLDivElement).click();
    }
  };

  const { data: dictionaries } = useConvertGeneratedQueryToSuspense(
    useGetDictionariesQuery,
    undefined,
  );
  const tracksList = dictionaries.getDictionaries.tracks;

  const availableNewTrackNames = useMemo(() => {
    return tracksList.filter((tr) => {
      const tracksInUse =
        downpricingConfigsList
          .find((c) => c.country === country)
          ?.priceClusterConfigs.find((cl) => cl.priceCluster === cluster)
          ?.trackConfigs.map((tr) => tr.track) ?? [];
      return !tracksInUse.includes(tr);
    });
  }, [tracksList, downpricingConfigsList, cluster, country]);

  const [isEditMode, setEditMode] = useState(false);
  const isMutating = useIsMutating() > 0;
  const isFetching = useIsFetching() > 0;

  const methods = useForm<DownpricingConfigSchemaType>({
    resolver: zodResolver(DownpricingConfigSchema),
    // defaultValues: phaseConfigs,
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  });

  const renderTitle = ({ key, title }: { key: string; title?: string }) => {
    return <b data-qa-selector={`${key}-header`}>{title ?? key}</b>;
  };
  const renderColumn = (
    val: {
      value: string | number;
      formFieldName: FieldPath<DownpricingConfigSchemaType>;
    },
    record: (typeof dataExperimentSource)[number],
  ) => {
    return isEditMode ? (
      record.fieldType === 'number' ? (
        <FormItem control={methods.control} name={val.formFieldName} required>
          <InputNumber
            // style={{ background: methods.getFieldState(val.formFieldName).isDirty ? '#ffff00' : '#fff' }}
            controls={false}
            min={0}
            max={999}
            size="small"
            data-qa-selector="ratioValueInput"
          />
        </FormItem>
      ) : (
        <FormItem control={methods.control} name={val.formFieldName} required>
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            size="small"
            data-qa-selector="ratioValueInput"
          />
        </FormItem>
      )
    ) : (
      <span>{String(val.value)}</span>
    );
  };
  const renderTitleColumn = (value: string) => <b>{value}</b>;
  const renderDeltaColumn = (val: {
    value: DownPricingPercentageDeltaConfig | DownPricingCarvanaDeltaConfig;
    formFieldsPath: FieldPath<DownpricingConfigSchemaType>;
  }) =>
    isEditMode ? (
      <DeltaAmount
        isCarvana={!('deltaPercentage' in val.value)}
        formFieldsPath={val.formFieldsPath}
      />
    ) : (
      <span>
        {'deltaPercentage' in val.value
          ? val.value.deltaPercentage
          : `${val.value.deltaAmount1}, ${val.value.deltaAmount2}`}
      </span>
    );

  const prepareExperimentConfigTableData = (
    phaseConfigs: DownPricingPhaseConfigs,
  ) => {
    return Object.entries(phasesTableRowConfig).map(([key, entry]) => {
      const phasesCfg = Object.keys(phaseConfigs).reduce<
        Record<PhasesKeys, { value: number | boolean; formFieldName: string }>
      >(
        (acc, curr) => {
          acc[curr as PhasesKeys] = {
            value: phaseConfigs[curr as PhasesKeys][key as TableRowKeys],
            formFieldName: `${curr}.${key}`,
          };
          return acc;
        },
        {
          fresh: { value: 0, formFieldName: '' },
          turnover1: { value: 0, formFieldName: '' },
          turnover2: { value: 0, formFieldName: '' },
          offloading: { value: 0, formFieldName: '' },
        },
      );

      return {
        title: entry.title,
        fieldType: entry.fieldType,
        ...phasesCfg,
      };
    });
  };

  const prepareDeltasConfigTableData = (
    phaseConfigs: DownPricingPhaseConfigs,
  ) => {
    const dataItems: {
      title: string;
      fresh: {
        value: DownPricingPercentageDeltaConfig | DownPricingCarvanaDeltaConfig;
        formFieldsPath: string;
      };
      turnover1: {
        value: DownPricingPercentageDeltaConfig | DownPricingCarvanaDeltaConfig;
        formFieldsPath: string;
      };
      turnover2: {
        value: DownPricingPercentageDeltaConfig | DownPricingCarvanaDeltaConfig;
        formFieldsPath: string;
      };
      offloading: {
        value: DownPricingPercentageDeltaConfig | DownPricingCarvanaDeltaConfig;
        formFieldsPath: string;
      };
    }[] = [];
    phaseConfigs.fresh.strategyConfigs.forEach((cfg, idx) => {
      Object.entries(deltasTableRowConfig).forEach(([key, value]) => {
        const phasesCfg = Object.keys(phaseConfigs).reduce<
          Record<
            PhasesKeys,
            {
              value:
                | DownPricingPercentageDeltaConfig
                | DownPricingCarvanaDeltaConfig;
              formFieldsPath: string;
            }
          >
        >(
          (acc, curr) => {
            acc[curr as PhasesKeys] = {
              value: phaseConfigs[curr as PhasesKeys].strategyConfigs[idx]
                ?.deltaConfigs[key as DeltasTableRowKeys] ?? {
                deltaPercentage: 0,
              },
              formFieldsPath: `${curr}.strategyConfigs.${idx}.deltaConfigs.${key}`,
            };

            return acc;
          },
          {
            fresh: {
              value: { deltaPercentage: 0 },
              formFieldsPath: '',
            },
            turnover1: {
              value: { deltaPercentage: 0 },
              formFieldsPath: '',
            },
            turnover2: {
              value: { deltaPercentage: 0 },
              formFieldsPath: '',
            },
            offloading: {
              value: { deltaPercentage: 0 },
              formFieldsPath: '',
            },
          },
        );

        dataItems.push({
          title: `${cfg.strategy}-${value.title}`,
          ...phasesCfg,
        });
      });
    });
    return dataItems;
  };

  const dataExperimentSource = useMemo(
    () => prepareExperimentConfigTableData(phaseConfigs),
    [phaseConfigs],
  );
  const dataDeltasSource = useMemo(
    () => prepareDeltasConfigTableData(phaseConfigs),
    [phaseConfigs],
  );
  const tableColOptions = useMemo(
    () => Object.keys(phaseConfigs),
    [phaseConfigs],
  );

  const collapseItems: CollapseProps['items'] = [
    {
      key: 1,
      label: (
        <b style={{ fontSize: '20px', color: '#abaeab', lineHeight: 'normal' }}>
          Experiment config:
        </b>
      ),
      children: (
        <Table<(typeof dataExperimentSource)[number]>
          data-qa-selector="swTable"
          pagination={false}
          size="small"
          dataSource={dataExperimentSource}
          tableLayout={'fixed'}
          rowKey="title"
        >
          <Column
            dataIndex="title"
            title={() => renderTitle({ key: 'title', title: 'Phase' })}
            render={renderTitleColumn}
          />
          {tableColOptions.map((key) => {
            return (
              <Column
                key={key}
                dataIndex={key}
                title={() => renderTitle({ key })}
                render={renderColumn}
              />
            );
          })}
        </Table>
      ),
    },
    {
      key: 2,
      label: (
        <b style={{ fontSize: '20px', color: '#abaeab', lineHeight: 'normal' }}>
          Delta percentage:
        </b>
      ),
      children: (
        <Table<(typeof dataDeltasSource)[number]>
          data-qa-selector="swTable"
          pagination={false}
          size="small"
          dataSource={dataDeltasSource}
          tableLayout={'fixed'}
          rowKey="title"
        >
          <Column
            dataIndex="title"
            width={350}
            title={() => renderTitle({ key: 'title', title: 'Phase' })}
            render={renderTitleColumn}
          />
          {tableColOptions.map((key) => {
            return (
              <Column
                key={key}
                dataIndex={key}
                title={() => renderTitle({ key })}
                render={renderDeltaColumn}
              />
            );
          })}
        </Table>
      ),
    },
  ];

  return (
    <>
      <HStack className={css({ mb: '15px' })}>
        <Button
          type="primary"
          data-qa-selector="editTrackConfigButton"
          disabled={isEditMode || isFetching || isMutating}
          onClick={() => {
            setEditMode(true);
            setEditedTracksCount((c) => c + 1);
            methods.reset(phaseConfigs);
          }}
        >
          <EditOutlined /> Edit Track config
        </Button>
        <Button
          type="primary"
          data-qa-selector="editTrackConfigButton"
          disabled={!isEditMode || !methods.formState.isDirty}
          onClick={() => {
            if (Object.values(methods.formState.errors).length === 0) {
              setTrackConfigData({
                country,
                cluster,
                track,
                phaseConfigs: methods.getValues(),
              });
              setEditMode(false);
              setEditedTracksCount((c) => c - 1);
            }
          }}
        >
          <CheckOutlined /> Set track config
        </Button>
        <Button
          type="default"
          data-qa-selector="cancelEditTrackConfigButton"
          disabled={!isEditMode}
          onClick={() => {
            methods.reset(phaseConfigs);
            setEditMode(false);
            setEditedTracksCount((c) => c - 1);
          }}
        >
          Discard changes
        </Button>
        <Popover
          destroyTooltipOnHide={true}
          content={
            <>
              Select new Track name:
              <Form name="selectTrackClone" onFinish={handleCloneTrack}>
                <Form.Item<FieldType>
                  name="track"
                  rules={[{ required: true, message: 'Please select track!' }]}
                >
                  <Select
                    options={availableNewTrackNames.map((i) => ({
                      label: i,
                      value: i,
                    }))}
                    data-qa-selector="trackCloneSelect"
                  ></Select>
                </Form.Item>
                <Button type="primary" htmlType="submit">
                  Clone
                </Button>
              </Form>
            </>
          }
          title="Clone Track config"
          trigger="click"
          open={isClonePopoverOpen}
          onOpenChange={setIsClonePopoverOpen}
        >
          <Button
            type="default"
            data-qa-selector="cloneTrackConfigButton"
            disabled={isEditMode}
          >
            <CopyOutlined /> Clone Track config
          </Button>
        </Popover>
      </HStack>
      <FormProvider {...methods}>
        <Form
          layout="vertical"
          data-qa-selector="downpricingConfigFormWrapper"
          noValidate={true}
        >
          <Collapse items={collapseItems} ghost defaultActiveKey={[1, 2]} />
        </Form>
        {/* {import.meta.env.DEV && <DevTool control={methods.control} />} */}
      </FormProvider>
    </>
  );
};
